import React from 'react';
import { useIntl } from 'react-intl';
import { OrgActions } from '../../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import Enumerations, {
  LocalEnumerations,
  getHeadersAsync,
  getServerErrors,
} from '../../../../systemUtils/common/CommonHelpers';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import internalUserActions from '../../../../systemUtils/userUtils/InternalUserActions';
import pricingActions from '../../../../systemUtils/pricing/PricingActions';
import OrganisationTypes from '../../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import {
  ControlState,
  KeyValuePair,
} from '../../../../sysObjects/common.types';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormTextCapture from '../../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormDropDown from '../../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormCheckbox from '../../../../systemComponents/sharedControls/formControls/formCheckbox/FormCheckbox';
import FormTextArea from '../../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import CommonPageContext from '../../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';

import './BillingCreateUpdate.css';
import {
  SvgEmptyRadio,
  SvgFilter,
  SvgGood,
  SvgWarning,
} from '../../../../systemComponents/sharedControls/svg';
import ListTable from '../../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ServiceDefinitionActions from '../../../../systemUtils/services/ServiceDefinitionActions';
import ServiceDefinition_Types from '../../../../sysObjects/apiModels/ServiceDefinition.types';
import BillingCreateUpdate_Types from './BillingCreate.types';
import ListTableRow from '../../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import PriceEditing from '../../../../systemComponents/targetedPageControls/pricing/billing/priceEditing/PriceEditing';
import StatusBlock from '../../../../systemComponents/sharedControls/reusableBlocks/statusBlock/StatusBlock';
import { useUserSettingsContext } from '../../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import BaseBlock from '../../../../systemComponents/sharedControls/reusableBlocks/baseBlock/BaseBlock';
import ButtonBox from '../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';

const OrganisationalBillingCreateUpdate: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id, orgId } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);
  const { userSettings } = useUserSettingsContext();
  const formats = Enumerations.getDeliveryFormats(intl.locale);
  const conditions = Enumerations.getRelatedConditions(intl.locale);
  const [pricingHasChanged, setPricingHasChanged] =
    React.useState<boolean>(true);
  const formRefs = [
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [React.useRef<FormControlRef>(null)],
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reloadRequired, setReloadRequired] = React.useState<boolean>(true);
  const [resetPriceListSelected, setResetPriceListSelected] = React.useState<boolean>(false);
  const [formData, setFormData] =
    React.useState<OrganisationTypes.billingDetails>(
      OrgActions.createDefaultBillingDetails(orgId || ''),
    );

  const [caseManagers, setCaseManagers] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [pricingDefinitions, setPricingDefinitions] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [tableLoading, setTableLoading] = React.useState<boolean>(true);
  const [supportedCurrencies, setSupportedCurrencies] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [taxRegimes, setTaxRegimes] = React.useState<KeyValuePair<string>[]>(
    [],
  );

  const [showCustomPrices, SetShowCustomPrices] =
    React.useState<boolean>(false);
  const [servicePricing, setServicePricing] = React.useState<
    BillingCreateUpdate_Types.PricingItem[]
  >([]);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);

  const [pageStates, setPages] = React.useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pages[0],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[1],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[2],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[3],
      value: 'neutral',
    },
  ]);

  const pageChange = (oldPage: number) => {
    const validationResults = formRefs[oldPage].map((ref) =>
      ref.current?.triggerValidation(),
    );
    const allValid = validationResults.every((result) => result === true);

    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[oldPage] = {
        ...updatedPages[oldPage],
        value: allValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  };

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | boolean | null
    >,
  ) => {
    if (
      result.fieldId === 'pricingDefinitionId' ||
      result.fieldId === 'billingAccount.currency' ||
      result.fieldId === 'billingAccount.taxCode'
    ) {
      setPricingHasChanged(true);
    }
    if (result.id.startsWith('billingAccount.')) {
      const billingField = result.id.replace('billingAccount.', '');

      if (billingField.startsWith('address.')) {
        const addressField = billingField.replace('address.', '');

        setFormData((prevData) => ({
          ...prevData,
          billingAccount: {
            ...prevData.billingAccount,
            address: {
              ...prevData.billingAccount.address,
              [addressField]: result.value,
            },
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          billingAccount: {
            ...prevData.billingAccount,
            [billingField]: result.value,
          },
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: result.value,
      }));
    }
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const calculatePriceDifference = (
    orgPrice: number,
    templatePrice: number,
  ): number => {
    return parseFloat((orgPrice - templatePrice).toFixed(2));
  };

  const loadDataAsync = async () => {
    setReloadRequired(false);
    const headers = await getHeadersAsync(userClaims, instance);
    await internalUserActions
      .queryUsersAsync(headers, {
        role: [LocalEnumerations.Roles.CaseManager],
        status: [
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Active,
        ],
      })
      .then((rslt) => {
        if (rslt.isFailure || rslt.result === null) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            '/Organisations',
          );
          return;
        }
        setCaseManagers(
          rslt.result!.map((item) => {
            return { key: item.id, value: item.name };
          }),
        );
      })
      .catch(() => {
        showMessage(
          locales.ApiResponses.invalidID,
          'negative',
          '/Organisations',
        );
        return;
      });

    setSupportedCurrencies(
      Enumerations.getSupportedCurrencies(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      }),
    );

    setTaxRegimes(
      Enumerations.getSupportedTaxes(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      }),
    );

    await OrgActions.getOrgByIDAsync(orgId!, headers).then((orgResult) => {
      if (orgResult.isFailure || !orgResult.result) {
        showMessage(
          locales.ApiResponses.loadingFailed,
          'negative',
          '/Organisations',
        );
        return orgResult;
      }

      let crumb = [
        ...locales.breadcrumbs.base,
        {
          label: orgResult.result!.name,
          key: orgResult.result!.id,
          link: `/Organisations/${orgResult.result!.id}/edit`,
        },
      ];
      if (!id) {
        crumb.push(locales.breadcrumbs.create);
      } else {
        crumb.push(locales.breadcrumbs.edit);
      }
      context?.handleCrumbUpdate(crumb);
    });

    await pricingActions
      .findActivePricingDefinitionsAsync(null, headers)
      .then((rslt) => {
        if (rslt.isFailure) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            '/Organisations',
          );
          return;
        }
        setPricingDefinitions(
          rslt.result!.map((item) => {
            return { key: item.id, value: item.name };
          }),
        );
      })
      .catch(() => {
        showMessage(
          locales.ApiResponses.loadingFailed,
          'negative',
          '/Organisations',
        );
        return;
      });

    setIsLoading(false);

    if (!id) {
      setIsLoading(false);
      return;
    }

    await OrgActions.getBillingById(id, headers)
      .then((result) => {
        if (result.isFailure) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            '/Organisations',
          );
          return;
        }

        if (orgId) {
          Promise.all([
            ServiceDefinitionActions.findAsync(
              {
                active: LocalEnumerations.ServiceDefinitionStatuses.Active,
              },
              headers,
            ),
            pricingActions.getPricingDefinitionAsync(
              result.result?.pricingDefinitionId!,
              headers,
            ),
            OrgActions.getPricingByOrg(orgId!, headers),
          ]).then((results) => {
            if (!results.every((result) => result.isSuccess === true)) {
              showMessage(
                locales.ApiResponses.loadingFailed,
                'negative',
                '/Organisations',
              );
              return;
            }

            const serviceDefResult = results[0].result!;
            const pricingDefResult = results[1]
              .result!.pricingDefinitionItems.filter(
                (x) => x.validTo === undefined || x.validTo === null,
              )
              .sort(
                (a, b) =>
                  new Date(a.validFrom).getTime() -
                  new Date(b.validFrom).getTime(),
              );
            const orgPricingResult = results[2]
              .result!.filter(
                (x) => x.validTo === undefined || x.validTo === null,
              )
              .sort(
                (a, b) =>
                  new Date(a.validFrom).getTime() -
                  new Date(b.validFrom).getTime(),
              );
            var symbol = Enumerations.getSupportedCurrencies(intl.locale).find(
              (item) => item.code === result.result?.billingAccount.currency,
            )?.symbol;
            setServicePricing(
              serviceDefResult.map(
                (item: ServiceDefinition_Types.RetrievedServiceDefinition) => {
                  var rslt: BillingCreateUpdate_Types.PricingItem = {
                    serviceId: item.id,
                    name: ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
                      item,
                      formats,
                      conditions,
                    ),
                    orgPrice: null,
                    templatePrice: null,
                    difference: null,
                    isCustomPrice: null,
                    isExpanded: false,
                    currencySymbol: symbol || '',
                    organisationPriceId: null,
                  };

                  var pricingDefinition = pricingDefResult.find(
                    (pricingItem) =>
                      pricingItem.serviceDefinitionId === item.id,
                  );
                  if (pricingDefinition) {
                    rslt.templatePrice = pricingDefinition.unitPrice;
                    rslt.isCustomPrice = false;
                  }

                  var orgPrice = orgPricingResult.find(
                    (orgItem) => orgItem.serviceDefinitionId === item.id,
                  );

                  if (orgPrice) {
                    rslt.orgPrice = orgPrice.unitPrice;
                    rslt.isCustomPrice = orgPrice.isCustomisedPrice;
                    rslt.organisationPriceId = orgPrice.id;
                  }

                  if (orgPrice && pricingDefinition) {
                    rslt.difference = calculatePriceDifference(
                      rslt.orgPrice!,
                      rslt.templatePrice!,
                    );
                  }
                  return rslt;
                },
              ),
            );
          });
        }

        setFormData(result.result!);
        setTableLoading(false);
        setPricingHasChanged(false);
        setIsLoading(false);
      })
      .catch(() => {
        showMessage(
          locales.ApiResponses.loadingFailed,
          'negative',
          '/Organisations',
        );
        return;
      });
  };

  React.useEffect(() => {
    if (!reloadRequired) {
      return;
    }
    setReloadRequired(false);
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }
    if (!orgId) {
      showMessage(locales.ApiResponses.invalidID, 'negative', '/Organisations');
      return;
    }

    setSupportedCurrencies(
      Enumerations.getSupportedCurrencies(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      }),
    );

    setTaxRegimes(
      Enumerations.getSupportedTaxes(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      }),
    );

    setIsLoading(true);
    loadDataAsync();
  }, [reloadRequired]);

  const save = async (reload: boolean) => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation(),
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);
    if (!pageValid) {
      return;
    }
    
    OrgActions.saveBillingDetailsAsync(
      formData,
      await getHeadersAsync(userClaims, instance),
      id,
    )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }
        if (reload) {
          if (!id) {
            showMessage(
              locales.ApiResponses.saveSuccess,
              'positive',
              `/Organisations/${orgId}/Billing/edit/${id ? id : result.result!.replace(/"/g, '')}`,
            );
          } else {
            setIsLoading(true);
            loadDataAsync();
          }
          return;
        }

        showMessage(
          locales.ApiResponses.saveSuccess,
          'positive',
          '/Organisations',
        );
      })
      .catch(() => {
        showMessage(locales.ApiResponses.saveFailed, 'negative');
      });
  };

  const revertPriceList = async () => {
    if (!orgId) {
      return;
    }

    OrgActions.revertEntireOrganisationPriceListAsync(
      orgId,
      await getHeadersAsync(userClaims, instance),
    )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }

        showMessage(
          locales.ApiResponses.priceListRevertSuccess,
          'positive',
        );

        setReloadRequired(true);
      })
      .catch(() => {
        showMessage(locales.ApiResponses.saveFailed, 'negative');
      });
  };

  const renderRevertConfirmation = () => {
    return resetPriceListSelected && (
      <ButtonBox
        id={`confirm-revert-render`}
        key={`confirm-revert-render`}
        className="Confirm-Revert-Buttons"
        title={locales.controls.priceListRevertConfirmation}
        displayBorder={true}
        buttons={[
          {
            id: `confirm-revert-yes`,
            controlState: 'negative',
            label: locales.controls.priceListRevertConfirmationYes,
            onClick: () => {
              revertPriceList();
              setResetPriceListSelected(false);
            },
          },
          {
            id: `confirm-revert-no`,
            controlState: 'positive',
            label: locales.controls.priceListRevertConfirmationNo,
            onClick: () => {
              setResetPriceListSelected(false);
            },
          },
        ]}
      />
    )
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        backLabel={locales.controls.back}
        nextLabel={locales.controls.next}
        pageChangeAction={pageChange}
        pages={[
          {
            mode: pageStates[0].value as ControlState,
            name: pageStates[0].key,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="assignedRelationshipManager"
                  id="assignedRelationshipManager"
                  label={locales.controls.assignedRelationshipManager}
                  value={formData.assignedRelationshipManager}
                  onChange={handleFormChange}
                  ref={formRefs[0][0]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="assignedConsultingBusinessPsychologist"
                  id="assignedConsultingBusinessPsychologist"
                  label={
                    locales.controls.assignedConsultingBusinessPsychologist
                  }
                  value={formData.assignedConsultingBusinessPsychologist}
                  onChange={handleFormChange}
                  ref={formRefs[0][1]}
                  textInputType="text"
                />
                <FormDropDown
                  displayMode="Box"
                  id="assignedCaseManager"
                  fieldId="assignedCaseManager"
                  items={caseManagers}
                  label={locales.controls.assignedCaseManager}
                  defaultText={locales.common.Select_Default}
                  value={formData.assignedCaseManager}
                  onChange={handleFormChange}
                  ref={formRefs[0][2]}
                  multiple
                  size={4}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.assignedCaseManager} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormCheckbox
                  displayMode="Box"
                  id="isPORequired"
                  fieldId="isPORequired"
                  label={locales.controls.isPORequired}
                  value={formData.isPORequired}
                  onChange={handleFormChange}
                  ref={formRefs[0][3]}
                />
              </>
            ),
          },
          {
            mode: pageStates[1].value as ControlState,
            name: pageStates[1].key,
            enabled: true,
            orderNo: 2,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.fullName"
                  id="billingAccount.fullName"
                  label={locales.controls.fullName}
                  value={formData.billingAccount.fullName}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.fullName} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][0]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.emailAddress"
                  id="billingAccount.emailAddress"
                  label={locales.controls.emailAddress}
                  value={formData.billingAccount.emailAddress}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.emailAddress} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][1]}
                  textInputType="email"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line1"
                  id="billingAccount.address.line1"
                  label={locales.controls.line1}
                  value={formData.billingAccount.address.line1}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line1} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][2]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line2"
                  id="billingAccount.address.line2"
                  label={locales.controls.line2}
                  value={formData.billingAccount.address.line2}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line2} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][3]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line3"
                  id="billingAccount.address.line3"
                  label={locales.controls.line3}
                  value={formData.billingAccount.address.line3}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line3} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][4]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.postalCode"
                  id="billingAccount.address.postalCode"
                  label={locales.controls.postalCode}
                  value={formData.billingAccount.address.postalCode}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.postalCode} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][5]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.country"
                  id="billingAccount.address.country"
                  label={locales.controls.country}
                  value={formData.billingAccount.address.country}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.country} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][6]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.telephoneNumber"
                  id="billingAccount.telephoneNumber"
                  label={locales.controls.telephoneNumber}
                  value={formData.billingAccount.telephoneNumber}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.telephoneNumber} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][7]}
                  textInputType="tel"
                />
              </>
            ),
          },
          {
            mode: pageStates[2].value as ControlState,
            name: pageStates[2].key,
            enabled: true,
            orderNo: 3,
            showAsOrdered: true,
            content: (
              <div className="Organisations-Billing-PricingDetails">
                <FormDropDown
                  displayMode="Box"
                  id="pricingDefinitionId"
                  fieldId="pricingDefinitionId"
                  items={pricingDefinitions}
                  label={locales.controls.pricingDefinitionId}
                  defaultText={locales.common.Select_Default}
                  value={formData.pricingDefinitionId}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.pricingDefinitionId} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][0]}
                  helpMessage={id ? locales.controls.priceChange : undefined}
                />
                <FormDropDown
                  displayMode="Box"
                  id="billingAccount.currency"
                  fieldId="billingAccount.currency"
                  items={supportedCurrencies}
                  label={locales.controls.currency}
                  defaultText={locales.common.Select_Default}
                  value={formData.billingAccount.currency}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.currency} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][1]}
                  helpMessage={id ? locales.controls.currencyChange : undefined}
                />
                <FormDropDown
                  displayMode="Box"
                  id="billingAccount.taxCode"
                  fieldId="billingAccount.taxCode"
                  items={taxRegimes}
                  label={locales.controls.taxCode}
                  defaultText={locales.common.Select_Default}
                  value={formData.billingAccount.taxCode}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.taxCode} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][2]}
                  helpMessage={id ? locales.controls.taxChange : undefined}
                />
                <div className="Info-Breaker">
                  <InformationButton
                    key="informationSave"
                    state={
                      pricingHasChanged && id
                        ? {
                          message: locales.controls.pricingHasChanged,
                          boxState: 'warning',
                          content: <SvgWarning fill="black" />,
                        }
                        : undefined
                    }
                    buttonDetails={{
                      itemKey: 'informationSave',
                      clickEvent: () => save(true),

                      label: locales.controls.saveButton,
                      mode: 'positive',
                      testId: 'billing-save-button',
                    }}
                  >
                    <>{locales.controls.summaryReload}</>
                  </InformationButton>
                </div>
                {id && pricingHasChanged === false && (
                  <ListTable
                    className="ListTable-Pricing"
                    isLoading={tableLoading || isLoading}
                    labels={{
                      of: '*of',
                      emptyMessage: '*emptyMessage',
                      size: '*size',
                      tableName: locales.controls.table.name,
                    }}
                    pagingDetails={{
                      currentPageSize: userSettings.startingPageSize,
                      pageSizes: userSettings.pageSizes,
                    }}
                    filter={{
                      isVisible: showFilter,
                      onStateChange(state) {
                        setShowFilter(state);
                      },
                    }}
                    rows={servicePricing.filter((item) => {
                      if (showCustomPrices === true) {
                        return item.isCustomPrice === true;
                      }
                      return true;
                    }).map((item, index) => {
                      return (
                        <ListTableRow
                          id={item.serviceId}
                          isExpanded={item.isExpanded}
                          events={{
                            OnExpandChange: (
                              isExpanded: boolean,
                              id: string,
                            ) => {
                              setServicePricing((prevPricings) =>
                                prevPricings.map((pricing) =>
                                  pricing.serviceId === id
                                    ? { ...pricing, isExpanded }
                                    : {
                                      ...pricing,
                                      isExpanded: false,
                                      isButtonRowExpanded: false,
                                    },
                                ),
                              );
                            },
                          }}
                          isButtonRowExpanded={false}
                        >
                          <ListTableRow.Collapsed>
                            <div>
                              <span>{item.name}</span>
                            </div>
                            <div>
                              <span>
                                {item.orgPrice != null ? `${item.currencySymbol}${item.orgPrice}` : locales.controls.noPriceValue}
                              </span>
                            </div>
                            <div>
                              <span>
                                {item.templatePrice != null ? `${item.currencySymbol}${item.templatePrice}` : locales.controls.noPriceValue}
                              </span>
                            </div>
                            <div>
                              <StatusBlock
                                boxSize="small"
                                boxState={
                                  item.difference != null && item.difference > 0
                                    ? 'positive'
                                    : item.difference != null && item.difference! < 0
                                      ? 'negative'
                                      : item.difference == null
                                        ? 'warning'
                                        : 'neutral'
                                }
                                allowHover={false}
                                content={
                                  item.difference != null && item.difference > 0
                                    ? '+'
                                    : item.difference != null && item.difference < 0
                                      ? '-'
                                      : item.difference == null
                                        ? '!'
                                        : ''
                                }
                              />
                              <span>
                                {item.difference != null
                                  ? `${item.currencySymbol}${item.difference.toString().replace(/^-/, '')}`
                                  : locales.controls.missingPriceValue}
                              </span>
                            </div>
                          </ListTableRow.Collapsed>
                          <ListTableRow.Expanded>
                            <PriceEditing
                              serviceId={item.serviceId}
                              labels={{
                                cancelButton:
                                  locales.controls.PriceEditing.cancelButton,
                                saveButton:
                                  locales.controls.PriceEditing.saveButton,
                                requiredField:
                                  locales.controls.PriceEditing.requiredField,
                                requiredFieldMessage:
                                  locales.controls.PriceEditing
                                    .requiredFieldMessage,
                                controlLabel:
                                  locales.controls.PriceEditing.controlLabel,
                                errorMessage:
                                  locales.controls.PriceEditing.errorMessage,
                                actionLabel:
                                  locales.controls.PriceEditing.actionLabel,
                                revertLabel:
                                  locales.controls.PriceEditing.revertLabel,
                                revertErrorMessage:
                                  locales.controls.PriceEditing.revertErrorMessage,
                                revertConfirmation:
                                  locales.controls.PriceEditing.revertConfirmation,
                                revertConfirmationNo:
                                  locales.controls.PriceEditing.revertConfirmationNo,
                                revertConfirmationYes:
                                  locales.controls.PriceEditing.revertConfirmationYes,
                              }}
                              price={item.orgPrice}
                              isCustomised={item.isCustomPrice ?? false}
                              cancelPrice={() => {
                                setServicePricing((prevPricings) =>
                                  prevPricings.map((pricing) => ({
                                    ...pricing,
                                    isExpanded: false,
                                  })),
                                );
                              }}
                              revertPrice={() => {
                                if (!item.organisationPriceId || item.organisationPriceId === null) {
                                  showMessage(
                                    `${locales.ApiResponses.unableToRevertPrice} ${item.name}`,
                                    'negative',
                                  );
                                  return;
                                }

                                getHeadersAsync(userClaims, instance).then(
                                  (rslt) => {
                                    if (rslt.isFailure) {
                                      showMessage(
                                        `${locales.ApiResponses.unableToRevertPrice} ${item.name}`,
                                        'negative',
                                      );
                                      return;
                                    }
                                    OrgActions.revertSingleOrganisationPriceAsync(
                                      item.organisationPriceId!,
                                      rslt,
                                    ).then((result) => {
                                      if (result.isFailure) {
                                        showMessage(
                                          `${locales.ApiResponses.unableToRevertPrice} ${item.name}`,
                                          'negative',
                                        );
                                        return;
                                      }

                                      setServicePricing((prevPricings) =>
                                        prevPricings.map((pricing) =>
                                          pricing.serviceId === item.serviceId
                                            ? {
                                              ...pricing,
                                              organisationPriceId: pricing.templatePrice === null ? null : pricing.organisationPriceId,
                                              orgPrice: pricing.templatePrice,
                                              difference:
                                                pricing.templatePrice
                                                  ? 0
                                                  : null,
                                              isExpanded: false,
                                              isCustomPrice: false,
                                            }
                                            : pricing,
                                        ),
                                      );

                                      showMessage(
                                        `${locales.ApiResponses.updatedPrice} ${item.name}`,
                                        'positive',
                                      );
                                    });
                                  },
                                );
                              }}
                              setPrice={(price) => {
                                if (price === item.orgPrice) {
                                  return;
                                }
                                getHeadersAsync(userClaims, instance).then(
                                  (rslt) => {
                                    if (rslt.isFailure) {
                                      showMessage(
                                        `${locales.ApiResponses.unableToUpdatePrice} ${item.name}`,
                                        'negative',
                                      );
                                      return;
                                    }
                                    OrgActions.savePricingAsync(
                                      {
                                        organisationId: orgId!,
                                        price: price,
                                        serviceDefinitionId: item.serviceId,
                                      },
                                      rslt,
                                    ).then((result) => {
                                      if (result.isFailure) {
                                        showMessage(
                                          `${locales.ApiResponses.unableToUpdatePrice} ${item.name}`,
                                          'negative',
                                        );
                                        return;
                                      }
                                      setReloadRequired(true);
                                      showMessage(
                                        `${locales.ApiResponses.updatedPrice} ${item.name}`,
                                        'positive',
                                      );
                                    });
                                  },
                                );
                              }}
                            />
                          </ListTableRow.Expanded>
                        </ListTableRow>
                      );
                    })}
                  >
                    <ListTable.Headers>
                      <div />
                      {locales.controls.table.headers.map(
                        (header: string, index: number) => {
                          return <div key={`Header_${index}`}>{header}</div>;
                        },
                      )}
                    </ListTable.Headers>
                    <ListTable.SearchOptions>
                      {showFilter && (
                        <div className="Filter-Box">
                          <BaseBlock
                            boxSize="medium"
                            className="Icon-Holder"
                            content={<SvgFilter className="dark-icon" />}
                          />
                          <div className="Radio-Selection">
                            <label
                              onClick={() => {
                                SetShowCustomPrices(true);
                              }}
                              htmlFor={`My-Case-True`}
                              className={`Text-Understated${showCustomPrices === true ? ' checked' : ''}`}
                            >
                              <input
                                type="radio"
                                id="true"
                                name={`My-Case-True`}
                                data-testid={`My-Case-True`}
                                value="true"
                              />
                              <div className="Radio-Icon-Holder">
                                {showCustomPrices === true ? (
                                  <SvgGood className="Radio-Icon" />
                                ) : (
                                  <SvgEmptyRadio
                                    className="Radio-Icon"
                                    data-testid={`True-Empty-False_SVG`}
                                  />
                                )}
                              </div>
                              {locales.searchAndFilter.customCases}
                            </label>
                            <label
                              onClick={() => {
                                SetShowCustomPrices(false);
                              }}
                              htmlFor={`My-Case-False`}
                              className={`Text-Understated${showCustomPrices === false ? ' checked' : ''}`}
                            >
                              <input
                                type="radio"
                                id="false"
                                name={`My-Case-False`}
                                data-testid={`My-Case-False`}
                                value="false"
                                checked={showCustomPrices === false}
                              />
                              <div className="Radio-Icon-Holder">
                                {showCustomPrices !== false ? (
                                  <SvgEmptyRadio className="Radio-Icon" />
                                ) : (
                                  <SvgGood className="Radio-Icon" />
                                )}
                              </div>

                              {locales.searchAndFilter.allCases}
                            </label>
                          </div>
                        </div>
                      )}
                    </ListTable.SearchOptions>
                  </ListTable>
                )}
              </div>
            ),
          },
          {
            mode: pageStates[3].value as ControlState,
            name: pageStates[3].key,
            enabled: true,
            orderNo: 4,
            showAsOrdered: true,
            content: (
              <FormTextArea
                displayMode="Box"
                id="notes"
                fieldId="notes"
                label={locales.controls.notes}
                value={formData.notes}
                onChange={handleFormChange}
                ref={formRefs[3][0]}
              />
            ),
          },
        ]}
      />

      <div className="Button-Container No-Margin-Bottom">
        <InformationButton
          key="informationSave"
          buttonDetails={{
            itemKey: 'informationSave',
            clickEvent: () => save(false),
            label: locales.controls.saveButton,
            mode: 'positive',
            testId: 'organisation-save-button'
          }}
        >
          <>{locales.controls.summary}</>
        </InformationButton>
        <InformationButton
          key="revertEntirePriceList"
          buttonDetails={{
            itemKey: 'revertPriceList',
            clickEvent: () => setResetPriceListSelected(true),
            label: locales.controls.priceListRevertLabel,
            mode: 'positive',
          }}
        >
          <>{locales.controls.priceListRevertDescription}</>
        </InformationButton>
      </div>
      {renderRevertConfirmation()}
    </div>
  );
};

export default OrganisationalBillingCreateUpdate;
